export const ABOUT_BUTTON_KEY = 'about'
export const ANNOTATION_TOGGLE_BUTTON_KEY = 'annotationToggle'
export const AUDIO_NEXT_BUTTON_KEY = 'audioNext'
export const AUDIO_PLAY_BUTTON_KEY = 'audioPlay'
export const AUDIO_PREVIOUS_BUTTON_KEY = 'audioPrevious'
export const AUDIO_FAST_FORWARD_BUTTON_KEY = 'audioFastForward'
export const AUDIO_REWIND_BUTTON_KEY = 'audioRewind'
export const SONG_REPEAT_BUTTON_KEY = 'songRepeat'
export const AUDIO_OPTIONS_BUTTON_KEY = 'audioOptions'
export const CAROUSEL_NAV_BUTTON_KEY = 'carouselNav'
export const CAROUSEL_PREVIOUS_BUTTON_KEY = 'carouselPrevious'
export const CAROUSEL_NEXT_BUTTON_KEY = 'carouselNext'
export const COPY_URL_BUTTON_KEY = 'copyUrl'
export const DOTS_SLIDE_BUTTON_KEY = 'dotsSlide'
export const DOTS_SLIDE_SELECT_BUTTON_KEY = 'slideSelect'
export const LYRIC_EAR_BUTTON_KEY = 'lyricEar'
export const LYRIC_EXPAND_BUTTON_KEY = 'lyricExpand'
export const LYRIC_SCROLL_BUTTON_KEY = 'lyricScroll'
export const NAV_BOOK_BUTTON_KEY = 'navBook'
export const NAV_SONG_BUTTON_KEY = 'navSong'
export const OVERVIEW_BUTTON_KEY = 'overview'
export const PROMO_PAGE_BUTTON_KEY = 'promoHomepage'
export const PROMO_BACK_BUTTON_KEY = 'promoBack'
export const PAGINATION_SLIDE_BUTTON_KEY = 'paginationSlide'
export const PAGINATION_PREVIOUS_BUTTON_KEY = 'paginationPrevious'
export const PAGINATION_NEXT_BUTTON_KEY = 'paginationNext'
export const POPUP_CLOSE_BUTTON_KEY = 'popupClose'
export const POPUP_NEXT_BUTTON_KEY = 'popupNext'
export const POPUP_PREVIOUS_BUTTON_KEY = 'popupPrevious'
export const ROOT_HOME_BUTTON_KEY = 'rootHome'
export const SCORES_BUTTON_KEY = 'scores'
export const TIPS_BUTTON_KEY = 'tips'
export const WORMHOLE_BUTTON_KEY = 'wormhole'
